<template>
  <main>
    <NavCmt />
    <HeaderCmt />

    <TitleDividerCmt :title="work" />
    <LineDivider />
    <div v-for="award in awards" :key="award.id">
      <AwardCmt :value="award" />
    </div>
  </main>
</template>

<script>
import TitleDividerCmt from './TitleDividerCmt.vue';
import HeaderCmt from './HeaderCmt.vue';
import AwardCmt from './AwardCmt.vue';
import { AWARDS } from '../constants/index.js';

export default {
  components: {
    TitleDividerCmt,
    HeaderCmt,
    AwardCmt,
  },

  data() {
    return {
      work: '수상 경력',
      awards: AWARDS,
    };
  },
  mounted() {
    const role = sessionStorage.getItem('admin');

    if (!role) {
      window.location.href = '/login';
    }
  },
};
</script>

<style scoped>
main {
  width: 70%;

  padding: 130px;
}
</style>
