<template>
  <div class="title-container">
    <div class="title">{{ value.title }}</div>
    <div class="date">{{ value.date }}</div>
  </div>
  <div class="contribution">{{ value.contribution }}</div>
  <div class="contents-container">
    <div
      v-for="content in value.contents"
      :key="content.subTitle"
      class="content-item"
    >
      <div class="sub-title">{{ content.subTitle }}</div>
      <ul class="element-list">
        <li v-for="element in content.elements" :key="element" class="element">
          {{ element }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.title-container {
  line-height: 150%;
  display: flex;
  gap: 8px;
  margin-top: 48px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.36px;
  color: #fd853a;
}

.date {
  color: #845f5f;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.24px;
}

.contribution {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.36px;
  margin-top: 57px;
  margin-bottom: 43px;
}

.contents-container {
  display: flex;
  flex-direction: column;
}

.sub-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 180%;
  letter-spacing: -0.3px;
  margin-bottom: 10px;
}

.element-list {
  margin-bottom: 50px;
}

.element {
  font-size: 24px;
  line-height: 180%;
}
</style>
