<template>
  <main>
    <NavCmt />
    <HeaderCmt />
    <TitleDividerCmt :title="work" />
    <LineDivider />

    <div v-for="resume in resumes" :key="resume.id">
      <ContributionCmt :value="resume" />
    </div>

    <TitleDividerCmt :title="edu" />
    <LineDivider />

    <div v-for="edu in edus" :key="edu.id">
      <ContributionCmt :value="edu" />
    </div>
    <div class="gpa">학점: {{ gpa }}</div>
    <div class="space" />
  </main>
</template>

<script>
import TitleDividerCmt from './TitleDividerCmt.vue';
import HeaderCmt from './HeaderCmt.vue';
import ContributionCmt from './ContributionCmt.vue';
import { RESUMES, EDUCATIONS } from '../constants/index.js';

export default {
  components: {
    TitleDividerCmt,
    HeaderCmt,
    ContributionCmt,
  },

  data() {
    return {
      work: 'Work Experience',
      edu: 'Education',
      resumes: RESUMES,
      edus: EDUCATIONS,
      gpa: '4.40',
    };
  },
  mounted() {
    const role = sessionStorage.getItem('admin');

    if (!role) {
      window.location.href = '/login';
    }
  },
};
</script>

<style scoped>
main {
  width: 70%;
  padding: 130px;
}

.gpa {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.36px;

  margin-top: 57px;
}

.space {
  height: 100px;
}
</style>
