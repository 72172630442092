export const AWARDS = [
  {
    id: 0,
    title: '과학기술정보통신부 장관상',
    date: '2023.08.31',
    name: '국가 우수 이공계 장학금',
    images: [
      {
        src: '/images/awards/award.jpeg',
        width: 300,
        height: 600,
        alt: 'awards image',
      },
    ],
  },
];
