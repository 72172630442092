<template>
  <div class="title-container">
    <div class="title">{{ value.title }}</div>
    <div class="date">{{ value.date }}</div>
  </div>
  <div class="name">{{ value.name }}</div>
  <img src="@/assets/award.jpeg" alt="Award Image" class="award-image" />
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.title-container {
  line-height: 150%;
  display: flex;
  gap: 8px;
  margin-top: 48px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.36px;
  color: #fd853a;
}

.date {
  color: #845f5f;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.24px;
}

.name {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.36px;
  margin-top: 57px;
  margin-bottom: 43px;
}

.award-image {
  width: 300px;
  height: 600px;
  margin-top: 20px;

  margin-bottom: 100px;
}
</style>
