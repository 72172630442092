<template>
  <div class="header-container">
    <header class="headers">
      <section class="header">
        <div class="header-text">
          <span class="highlight">주도적인 개발자</span>
          김성민입니다.
        </div>
      </section>
      <div class="common-text">
        <div>
          사용자와 직접적으로 대면하는 제품을 만드는것에 매력을 느껴 웹 개발을
          시작했습니다.
        </div>
        <div>
          지식공유와 문서화를 좋아하여 프로젝트에 필요한 기술을 학습하고
          팀원들과 함께 공유합니다.
        </div>
        <div>
          트러블 슈팅 관리를 위해 템플릿을 제공하여 일관된 문서를 작성합니다.
        </div>
        <div>리뷰하기 편한 PR을 지향합니다.</div>
      </div>
      <div class="contact-container">
        <a
          class="contact"
          href="https://github.com/Collection50"
          target="_blank"
        >
          <img src="@/assets/github.svg" alt="github" width="24px" />
          @Collection50
        </a>
        <a
          class="contact"
          href="https://github.com/Collection50"
          target="_blank"
        >
          <img src="@/assets/call.svg" alt="phone" width="24px" />
          010-3035-5265
        </a>
        <a class="contact" href="mailto:fkaus65@naver.com" target="_blank">
          <img src="@/assets/mail.svg" alt="email" width="24px" />
          fkaus65@naver.com
        </a>
      </div>
    </header>
    <div class="img-container">
      <img
        src="@/assets/me.png"
        alt="me"
        class="me-img"
        width="300px"
        height="300px"
      />
    </div>
  </div>
</template>

<style scoped>
.header-container {
  width: 100%;

  display: flex;
  justify-content: space-between;

  /* border: 1px solid black; */
}
.headers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 87px;
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.header-text {
  display: flex;
  gap: 10px;
  text-align: center;
  font-size: 3em;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.975px;
}

.common-text {
  font-size: 1em;
  line-height: 1.75;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}
.highlight {
  color: #fd853a;
}

.contact-container {
  width: fit-content;
  display: flex;
  gap: 15px;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #e4e7ec;
  padding: 0 10px 0 10px;
  margin-top: 30px;
  backdrop-filter: blur(7.5px);
}

.contact {
  font-weight: 600;
  display: flex;
  gap: 5px;
  align-items: center;
  color: black;
}

.img-container {
  width: 300px;
  height: 300px;

  border-radius: 50%;

  overflow: hidden;
}
</style>
