<template>
  <main id="app">
    <nav class="nav-container">
      <section class="nav-button-container">
        <router-link to="/" class="nav-button" :style="getButtonStyle('/')">
          Home
        </router-link>
        <router-link
          to="/resume"
          class="nav-button"
          :style="getButtonStyle('/resume')"
        >
          Resume
        </router-link>
        <router-link
          to="/awards"
          class="nav-button"
          :style="getButtonStyle('/awards')"
        >
          Awards
        </router-link>
        <router-link
          to="/login"
          class="nav-button"
          :style="getButtonStyle('/login')"
          @click.prevent="handleLogout"
        >
          {{ loginButtonText }}
        </router-link>
      </section>
    </nav>
    <router-view />
  </main>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      loginButtonText: 'Login',
    };
  },
  methods: {
    getButtonStyle(path) {
      return {
        background: this.$route.path === path ? '#fd853a' : '',
      };
    },

    updateLoginButtonText() {
      this.loginButtonText = sessionStorage.getItem('admin')
        ? 'Logout'
        : 'Login';
    },

    handleLogout() {
      if (sessionStorage.getItem('admin')) {
        sessionStorage.removeItem('admin');
        this.updateLoginButtonText();
      }
    },
  },
  watch: {
    $route: 'updateLoginButtonText',
  },
  mounted() {
    this.updateLoginButtonText();
  },
};
</script>

<style scoped>
#app {
  height: 100vh;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;
}
.nav-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
}

.nav-button {
  text-decoration: none;
  font-size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border-radius: 60px;
  gap: 10px;
  text-decoration: none;
  color: white;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 350px;
  height: 56px;
  border-radius: 50px;
  background: #171717;
}

.nav-button:hover {
  color: black;
  background: #fd853a;
}
</style>
