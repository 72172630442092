<template>
  <hr class="hr" />
</template>

<style scoped>
.hr {
  width: 100%;
  height: 1px;
  border: 0;
  background: #e6e6e6;
}
</style>
