<template>
  <div class="form-container">
    <form @submit.prevent="onSubmitHandler">
      <div class="input-section">
        <div class="greeting">환영합니다 !</div>
        <input
          class="input-style"
          v-for="format in loginFormat"
          :key="format.key"
          :type="format.key === 'id' ? 'text' : 'password'"
          :placeholder="format.placeholder"
          @input="(e) => changeHandler(e, format.key)"
          :autocomplete="format.key === 'password' ? 'off' : 'on'"
        />
      </div>
      <button class="button">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  created() {
    const role = sessionStorage.getItem('admin');

    if (role) {
      window.location.href = '/';
    }
  },
  data() {
    return {
      loginFormat: [
        { key: 'id', placeholder: '아이디를 입력하세요.' },
        { key: 'password', placeholder: '비밀번호를 입력하세요.' },
      ],
    };
  },
  methods: {
    changeHandler(e, key) {
      this.loginFormat[key] = e.target.value;
    },
    onSubmitHandler() {
      const { id } = this.loginFormat;
      const { password } = this.loginFormat;

      if (id === '1234' && password === '1234') {
        sessionStorage.setItem('admin', 'role');
        window.location.href = '/';
      } else {
        window.alert('아이디나 비밀번호가 일치하지 않습니다.');
      }
    },
  },
};
</script>

<style scoped>
.form-container {
  width: 25%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

form {
  width: 100%;
}

.input-style {
  width: 100%;
  height: 56px;
  font-size: 16px;

  padding: 12px;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  margin-bottom: 13px;
}

.button {
  font-weight: 700;
  font-size: 16px;

  width: 100%;
  height: 52px;

  border: none;
  border-radius: 8px;

  cursor: pointer;
  margin-top: 15px;
  color: white;
  background: #fd853a;
}
.greeting {
  font-size: 40px;
  font-weight: 600;
  color: #fd853a;
  width: 100%;
  position: absolute;
  top: -30%;
  left: 0%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  animation: growAnimation 2s infinite;
}

@keyframes growAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
