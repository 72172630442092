import { createWebHistory, createRouter } from 'vue-router';
import Landing from '@/components/LandingCmt.vue';
import Resume from '@/components/ResumeCmt.vue';
import Awards from '@/components/AwardsCmt.vue';
import Login from '@/components/LoginCmt.vue';

const routes = [
  {
    path: '/',
    component: Landing,
  },
  {
    path: '/resume',
    component: Resume,
  },
  {
    path: '/awards',
    component: Awards,
  },
  {
    path: '/login',
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
