<template>
  <section class="section">
    <div class="title">{{ title }}</div>
    <LineDividerCmt />
  </section>
</template>

<script>
import LineDividerCmt from './LineDividerCmt.vue';

export default {
  name: 'App',
  components: {
    LineDividerCmt,
  },
  props: {
    title: {
      type: String,
      default: 'Work Experience',
    },
  },
};
</script>

<style scoped>
.section {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.6px;

  margin-bottom: 48px;
  color: #344054;
}
</style>
